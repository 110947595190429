@import "~normalize.css";

@font-face {
		font-family:"Sneak Mono";
		src:url("/fonts/Sneak-Mono.woff2") format("woff2"),url("/fonts/Sneak-Mono.woff") format("woff"),url("/fonts/Sneak-Mono.otf") format("opentype");
		font-style:normal;font-weight:400;
}

@font-face {
		font-family:"Sneak Regular";
		src:url("/fonts/Sneak-Regular.woff2") format("woff2"),url("/fonts/Sneak-Regular.woff") format("woff"),url("/fonts/Sneak-Regular.otf") format("opentype");
		font-style:normal;font-weight:400;
}

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Sneak Mono", 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  line-height: 17px;
  background: #0a0a0a;
	scrollbar-width: none; /* Firefox 64 */
}

img {
  max-width: 100%;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.home-page {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.credit a {
  display: block;
  text-decoration: none;
  margin-top: 1rem;
  color: #8D8D8D;
}

.credit a:hover {
  color: white;
}

::-webkit-scrollbar {
		width: 0px;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
		background: #FF0000;
}
